import React, {useContext, useState} from 'react';
import {message, Typography} from "antd";
import explainReportApi from "services/explainReport";
import ConversationContext from "./ConversationContext";

const {Link} = Typography;


const Context = React.createContext(null);

// const REPORT_DEFAULT_PROMPT = "Increased right lower lobe opacity, concerning for infection. No evidence of pneumothorax."
const REPORT_DEFAULT_PROMPT = "1. nodular opacities in the left lower lung with additional small ground-glass opacities bilaterally may represent infection. chest ct recommended for further assessment given infectious symptoms. 2. abdominal wall varices of indeterminate etiology. 3. splenomegaly. 4. coronary artery calcification. acute findings were discussed with dr. ___ by dr. ___ by telephone at 6:54 p.m. on ___."
// const REPORT_DEFAULT_PROMPT = "HISTORY: 47 year old man status post liver transplant with persistent \n" +
//     "decreased mental status.  Rule out cerebral edema, central pontine \n" +
//     "myelinolysis.\n" +
//     "\n" +
//     "TECHNIQUE: Contiguous axial images of the head are obtained without IV \n" +
//     "contrast from the skull base to the vertex.\n" +
//     "\n" +
//     "CT HEAD W/O CONTRAST: The sulci and ventricles are appropriate for the \n" +
//     "patient's age.  No intra or extraaxial fluid collections or masses.  No shift \n" +
//     "of midline structures is observed.\n" +
//     "\n" +
//     "IMPRESSION: No evidence for cerebral edema."
const SLIDER_LEVEL_DEFAULT_VALUE = 0
const GPT_MODEL = "gpt-4"


const ProviderWrapper = (props) => {


    const {
        conversation,
        setConversation,
    } = useContext(ConversationContext);

    const [reportContent, setReportContent] = useState(REPORT_DEFAULT_PROMPT);
    const [commonQuestions, setCommonQuestions] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState("");
    const [currentResponse, setCurrentResponse] = useState("");
    const [levelSliderValue, setLevelSliderValue] = useState(SLIDER_LEVEL_DEFAULT_VALUE);
    const [explainModalContent, setExplainModalContent] = useState([{}, {}, {}]);

    const getChatGPTCompletion = async (prompt) => {
        let ret = await explainReportApi.getChatGPTCompletion(prompt, GPT_MODEL)
        console.log(ret)
        if (ret["response"] === undefined) {
            let x = "what to do?"
        }
        return ret["response"]["choices"][0]["message"]["content"]
    }


    const enhanceQuestion = async (text) => {
        // MAKES HUMAN RULES HERE ITS OKA
        // TODO HERE QUESTIONS ARE LIST, TOHANDLE IN THE FUTURE
        if (Array.isArray(text))
            text = text[0]
        let prompt = "Correct the phrasing of this question:"

        if (text.includes("suggestive"))
            prompt = prompt + "Why " + text
        else if (text.includes("define"))
            prompt = prompt + text + "?"
        return await getChatGPTCompletion(prompt)
    }

    const getNCommonQuestions = async (content, num_questions = 5, about = "radiology report") => {
        const question_prompt = "List up to " + num_questions + " questions an uninformed patient " +
            "would ask about this " + about + ": \"" + content + "\" ?"
        let questions = await getChatGPTCompletion(question_prompt)
        questions = questions.split("\n").map(sentence => {
            if (sentence.trim())
                return sentence.trim()
        }).filter(item => {
            return item
        })
        return questions
    }

    const getQuestionToLevel = (text, level) => {
        // BY DEFAULT ITS 0 AND I REMOVED SLIDER TO CHANGE
        if (level === 0)
            return "Please answer the question in 3 sentences or less at a 5th grade reading level. " +
                "Do not discuss symptoms or treatment. Question:\n" + text
        if (level === 1)
            return text
        if (level === 2)
            return "Explain me as if I was an expert: " + text
    }

    const getQuestionWithContext = (text) => {
        return text + 'Given the following context:' + reportContent
    }

    const getQuestionToPrompt = (text, level) => {
        let question = getQuestionToLevel(text, level)
        return getQuestionWithContext(question)
    }


    function onStartConversation() {
        console.log(currentQuestion)
        setConversation(
            [
                {
                    "message": currentQuestion,
                    "form": "human"
                },
                {
                    "message": currentResponse,
                    "form": "AI"
                },
            ]
        )
    }

    const onStartFollowUpQuestion = async () => {
        let content = explainModalContent.slice()
        let levelContent = content[levelSliderValue]

        if (!(typeof levelContent === "object" && !Array.isArray(levelContent) && levelContent !== null)) {
            message.error('Failed at getting follow-up question');
            return
        }
        if (!levelContent["response"]) {
            message.error('Failed at getting follow-up question');
            return
        }

        // Spin
        content[levelSliderValue]["followUpQuestions"] = null
        setExplainModalContent(content)

        // Question
        let questions = await getNCommonQuestions(levelContent["response"], 5, "explanation")
        content = explainModalContent.slice()
        content[levelSliderValue]["followUpQuestions"] = questions
        setExplainModalContent(content)
    }

    const getAnswerNewLevelSlider = async (level) => {
        if (currentQuestion === "") {
            message.error("Question is empty, something is wrong")
            return
        }
        setLevelSliderValue(level)
        await getQuestionAnswerPipeline(currentQuestion, level, false)
    }

    const getQuestionAnswerPipeline = async (question, level, enhance_question = false) => {
        // get question (maybe enhance)
        if (enhance_question)
            question = await enhanceQuestion(question)

        let newContent
        // If we are new question, reset modal content
        if (question === currentQuestion)
            newContent = explainModalContent.slice()
        else {
            newContent = [{}, {}, {}]
            setCurrentQuestion(question)
            setExplainModalContent(newContent)
        }

        // If content exists already
        if (Object.keys(newContent[level]).length > 0)
            return

        newContent = newContent.slice()
        newContent[level]["question"] = question
        setExplainModalContent(newContent)

        // Answer
        let prompt = getQuestionToPrompt(question, level)
        let ret = await getChatGPTCompletion(prompt)
        setCurrentResponse(ret)
        newContent = newContent.slice()
        newContent[level]["response"] = ret
        newContent[level]["showFollowUpButton"] = true
        setExplainModalContent(newContent)
    };


    const exposedValues = {
        reportContent,
        setReportContent,
        commonQuestions,
        setCommonQuestions,
        REPORT_DEFAULT_PROMPT,
        SLIDER_LEVEL_DEFAULT_VALUE,
        setExplainModalContent,
        explainModalContent,
        setLevelSliderValue,
        levelSliderValue,
        getQuestionAnswerPipeline,
        getNCommonQuestions,
        setCurrentQuestion,
        setCurrentResponse,
        getAnswerNewLevelSlider,
        onStartFollowUpQuestion,
    }

    return (
        <Context.Provider value={exposedValues}>{props.children}</Context.Provider>
    )
}
export {Context, ProviderWrapper};
export default Context;