import config from "services/config";


const saveAnnotations = (annotations, selectedSpecialty, username) => {
    return config.miscApi.post("/smmile/saveAnnotations", {
        annotations: annotations,
        speciality: selectedSpecialty,
        username: username,
    }).then(response => {
        return response.data
    }).catch(() => {
        return {
            error: 'Backend is offline or received critical error',
        }
    })
}

const retrieveAnnotations = (offset) => {
    return config.miscApi.post("/smmile/fetch-rows", {
        offset: offset,
    }).then(response => {
        return response.data
    }).catch(() => {
        return {
            error: 'Backend is offline or received critical error',
        }
    })
}


const removeAnnotations = (parquet) => {
    return config.miscApi.post("/smmile/removeAnnotations", {
        parquet: parquet,
    }).then(response => {
        return response.data
    }).catch(() => {
        return {
            error: 'Backend is offline or received critical error',
        }
    })
}


const exportedObject = {
    saveAnnotations,
    retrieveAnnotations,
    removeAnnotations,
}

export default exportedObject