import React, {useEffect, useState} from 'react';
import "./style/style.css";
import {Typography, message, Space, Row, Col, Card, Divider, Spin, Pagination} from 'antd';
import {Modal} from 'antd';
import smmileApi from "services/smmile";


const loadingOverlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    zIndex: 1000, // Ensure it covers other content
};


const SMMILEOverview = () => {
    const [annotations, setAnnotations] = useState([]);
    const [groupedAnnotations, setGroupedAnnotations] = useState({});
    const [backendError, setBackendError] = useState({});
    const [isBackendLoading, setIsBackendLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);


    useEffect(() => {
        setBackendError({})
        setIsBackendLoading(false)
        retrieveAnnotations(0)
    }, []);

    const retrieveAnnotations = async (offset) => {
        setIsBackendLoading(true)
        const response = await smmileApi.retrieveAnnotations(offset);
        setIsBackendLoading(false)
        if ("error" in response) {
            setBackendError(response)
            return
        }

        setAnnotations(response)
        setTotalRows(response["num_rows_total"]);
        // Group data by problem_id
        const groupedData = response["rows"].reduce((acc, item) => {
            acc[item.row.problem_id] = [...(acc[item.row.problem_id] || []), item];
            return acc;
        }, {});
        setGroupedAnnotations(groupedData)
        console.log(groupedData)
    }


    const handlePageChange = (page) => {
        setCurrentPage(page);
        retrieveAnnotations((page - 1) * 100);
    };


    const showConfirm = (parquetPath) => {
        Modal.confirm({
            title: 'Confirm Removal',
            content: 'Are you sure you want to remove this example?',
            okText: 'Remove',
            cancelText: 'Cancel',
            onOk: async () => {
                const response = await smmileApi.removeAnnotations(parquetPath);
                if ("deleted" in response) {
                    if (response["deleted"]) {
                        message.success("Sample deleted");
                    } else {
                        message.error("Cannot delete sample, something is wrong");
                    }
                } else {
                    message.error("Cannot delete sample, something is wrong");
                }
            },
        });
    };


    const renderProblemGroup = (key, problemGroup) => {
        const sortedGroup = problemGroup.sort((a, b) => a.row.order - b.row.order);

        return (
            <div className="site-card-wrapper" key={problemGroup[0].row.problem_id}>
                <Divider orientation="left">Author: {sortedGroup[0].row.author} -
                    Specialty: {sortedGroup[0].row.speciality}
                    - <a onClick={() => showConfirm(sortedGroup[0].row.parquet_path)}>[remove]</a>
                    - <a href={`/smmile/add?key=${key}&username=${sortedGroup[0].row.author}`}>[edit]</a>
                </Divider>
                <Row justify="space-evenly">
                    {sortedGroup.map((item, index) => (
                        <Col span={2} key={item.row.order}>
                            <Card
                                title={`${index === sortedGroup.length - 1 ? 'Final Problem' : `Example ${item.row.order}`}`}
                                hoverable
                                bordered={true}
                                cover={<img src={item.row.image_url} alt={`Example ${item.row.order}`}
                                            style={{maxWidth: "100%", maxHeight: 150}}/>}
                                style={{width: 240, height: 400}}

                            >

                                <div
                                    style={{fontSize: 16, fontWeight: 500, paddingBottom: 10}}>{item.row.question}</div>
                                <div style={{color: "grey"}}>
                                    Answer: {item.row.answer}<br/>
                                </div>
                                <p></p>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </div>
        );
    };

    console.log(totalRows)
    return (
        <>
            {isBackendLoading && (
                <div style={loadingOverlayStyle}>
                    <Spin tip="Loading..."/>
                </div>
            )}
            {backendError && Object.keys(backendError).length > 0 && backendError.constructor === Object &&
                <div>
                    <pre>{JSON.stringify(backendError, null, 2)}</pre>
                </div>
            }
            <Space direction="vertical">
                <Pagination
                    defaultCurrent={currentPage}
                    total={Math.floor(totalRows / 100) + 1}
                    defaultPageSize={1}
                    onChange={handlePageChange}
                />
                <Typography>
                    <>
                        {Object.entries(groupedAnnotations).map(([key, group]) => renderProblemGroup(key, group))}
                    </>
                </Typography>
            </Space>
        </>
    );
};

export default SMMILEOverview;
